import React, { Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { RIGHT_GRANTS } from '../../appConstants';
import NoRightsScreen from '../rights/NoRightsScreen';
import { IUserRight } from '../../@types/model/auth/user/UserRight';
import { Screen, AnimatedLoader } from '@zz2/zz2-ui';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    /**
     * Check if current user is associated with a right that corresponds to the destination url.
     * @param url
     */
    const hasMenuRight = (url : string) : boolean => {
        if (!session?.user) return false;
        if (session.user.rights.length < 1) return false;

        const userRights : Array<IUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const filteredUserRights : Array<IUserRight> = userRights.filter(x => !!x.isActive && x.right?.url === url);

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RIGHT_GRANTS.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routeProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }
            const Component = component;
            if (!hasMenuRight(routeProps.match.url)) {
                return <NoRightsScreen />;
            }
            return <Component {...routeProps} />;
        }
        return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
    };
    return (<Route {...rest} render={render} />);
};

{/* Home */}
const Home = React.lazy(() => import('../home/HomeScreen'));

{/* FAQ */}
const FrequentlyAskedQuestion = React.lazy(() => import('../frequentlyAskedQuestions/FrequentlyAskedQuestionScreen'));

{/* Crop */}
const LinkingManager = React.lazy(() => import('../crop/linkingManager/LinkingManager'));
const PlanningManager = React.lazy(() => import('../crop/planningManager/PlanningManager'));
const ApprovalManager = React.lazy(() => import('../crop/approvalManager/ApprovalManager'));
const CropVarietyManager = React.lazy(() => import('../crop/cropVariety/CropVarietyManager'));
const CropManager = React.lazy(() => import('../crop/transactions/CropManager'));

{/* Master Data */}
const MasterDataManager = React.lazy(() => import('../masterData/MasterDataManager'));
const DivisionManager = React.lazy(() => import('../masterData/division/DivisionManager'));
const DivisionHierarchyManager = React.lazy(() => import('../masterData/division/DivisionHierarchyManager'));
const SubdivisionManager = React.lazy(() => import('../masterData/subdivision/SubdivisionManager'));
const DepartmentManager = React.lazy(() => import('../masterData/department/DepartmentManager'));
const ProductionUnitManager = React.lazy(() => import('../masterData/productionUnit/ProductionUnitManager'));
const FieldManager = React.lazy(() => import('../masterData/field/FieldManager'));
const BlockManager = React.lazy(() => import('../masterData/block/BlockManager'));
const CommodityManager = React.lazy(() => import('../masterData/commodity/CommodityManager'));
const VarietyManager = React.lazy(() => import('../masterData/variety/VarietyManager'));
const ProjectManager = React.lazy(() => import('../masterData/project/ProjectManager'));
const NurseryManager = React.lazy(() => import('../masterData/nursery/NurseryManager'));
const RootStockManager = React.lazy(() => import('../masterData/rootStock/RootStockManager'));
const UnitOfMeasureManager = React.lazy(() => import('../masterData/unitOfMeasure/UnitOfMeasureManager'));
const ActiveIngredientManager = React.lazy(() => import('../masterData/chemical/activeIngredient/ActiveIngredientManager'));
const ChemicalCategoryManager = React.lazy(() => import('../masterData/chemical/chemicalCategory/ChemicalCategoryManager'));
const ChemicalElementManager = React.lazy(() => import('../masterData/chemical/chemicalElement/ChemicalElementManager'));
const ChemicalFormulationManager = React.lazy(() => import('../masterData/chemical/chemicalFormulation/ChemicalFormulationManager'));
const ChemicalProductManager = React.lazy(() => import('../masterData/chemical/chemicalProduct/ChemicalProductManager'));
const SafetyPrecautionManager = React.lazy(() => import('../masterData/safetyPrecaution/SafetyPrecautionManager'));
const SafetyPrecautionPPEManager = React.lazy(() => import('../masterData/safetyPrecautionPPE/SafetyPrecautionPPEManager'));
const SprayMethodManager = React.lazy(() => import('../masterData/sprayMethod/SprayMethodManager'));
const SeedTraySizeManager = React.lazy(() => import('../masterData/seedTraySize/SeedTraySizeManager'));
const PlantsPerHectareManager = React.lazy(() => import('../masterData/plantsPerHectare/PlantsPerHectareManager'));
const ActivityTypeManager = React.lazy(() => import('../masterData/activityType/ActivityTypeManager'));
const ActivityManager = React.lazy(() => import('../masterData/activity/ActivityManager'));
const SystemManager = React.lazy(() => import('../masterData/system/SystemManager'));
const PriorityManager = React.lazy(() => import('../masterData/priority/PriorityManager'));

{/* Season Planning */}
const SeasonProgramManager = React.lazy(() => import('../season/seasonProgram/SeasonProgramManager'));

{/* Rights */}
const RightManager = React.lazy(() => import('../rights/rightsManagement/RightManager'));
const UserManager = React.lazy(() => import('../rights/userManagement/UserManager'));

{/* Development */}
const LogManager = React.lazy(() => import('../logs/LogManager'));

{/* Packman */}
const IntakeSummaryManager = React.lazy(() => import('../packman/IntakeSummaryManager'));

const Routes = () : JSX.Element => {
    return (
        <Suspense fallback={<Screen><AnimatedLoader/></Screen>}>
            <Switch>
                <Route path={'/faq'} exact component={FrequentlyAskedQuestion} />
                <Route
                    path={'/'} exact
                    render={() : JSX.Element => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                <PrivateRoute exact path='/home' component={Home} />

                {/* Crop */}
                <PrivateRoute exact path='/crop/linking' component={LinkingManager} />
                <PrivateRoute exact path='/crop/planning' component={PlanningManager} />
                <PrivateRoute exact path='/crop/approval' component={ApprovalManager} />
                <PrivateRoute exact path='/crop/cropVariety' component={CropVarietyManager} />
                <PrivateRoute exact path='/crop/transactions' component={CropManager} />

                {/* Master Data */}
                <PrivateRoute exact path={'/masterData'} component={MasterDataManager}/>
                <PrivateRoute exact path={'/masterData/division'} component={DivisionManager}/>
                <PrivateRoute exact path={'/masterData/DivisionHierarchy'} component={DivisionHierarchyManager}/>
                <PrivateRoute exact path={'/masterData/subdivision'} component={SubdivisionManager}/>
                <PrivateRoute exact path={'/masterData/departments'} component={DepartmentManager}/>
                <PrivateRoute exact path={'/masterData/productionUnit'} component={ProductionUnitManager}/>
                <PrivateRoute exact path={'/masterData/field'} component={FieldManager}/>
                <PrivateRoute exact path={'/masterData/block'} component={BlockManager}/>
                <PrivateRoute exact path={'/masterData/commodities'} component={CommodityManager}/>
                <PrivateRoute exact path={'/masterData/variety'} component={VarietyManager}/>
                <PrivateRoute exact path={'/masterData/projects'} component={ProjectManager}/>
                <PrivateRoute exact path={'/masterData/nurseries'} component={NurseryManager}/>
                <PrivateRoute exact path={'/masterData/rootStock'} component={RootStockManager}/>
                <PrivateRoute exact path={'/masterData/unitOfMeasure'} component={UnitOfMeasureManager}/>
                <PrivateRoute exact path={'/masterData/activeIngredient'} component={ActiveIngredientManager}/>
                <PrivateRoute exact path={'/masterData/chemicalCategory'} component={ChemicalCategoryManager}/>
                <PrivateRoute exact path={'/masterData/chemicalElement'} component={ChemicalElementManager}/>
                <PrivateRoute exact path={'/masterData/chemicalFormulation'} component={ChemicalFormulationManager}/>
                <PrivateRoute exact path={'/masterData/chemicalProducts'} component={ChemicalProductManager}/>
                <PrivateRoute exact path={'/masterData/safetyPrecaution'} component={SafetyPrecautionManager}/>
                <PrivateRoute exact path={'/masterData/safetyPrecautionPpe'} component={SafetyPrecautionPPEManager}/>
                <PrivateRoute exact path={'/masterData/plantsPerHectare'} component={PlantsPerHectareManager}/>
                <PrivateRoute exact path={'/masterData/seedTraySize'} component={SeedTraySizeManager}/>
                <PrivateRoute exact path={'/masterData/sprayMethod'} component={SprayMethodManager}/>
                <PrivateRoute exact path={'/masterData/activityType'} component={ActivityTypeManager}/>
                <PrivateRoute exact path={'/masterData/activity'} component={ActivityManager}/>
                <PrivateRoute exact path={'/masterData/system'} component={SystemManager}/>
                <PrivateRoute exact path={'/masterData/priority'} component={PriorityManager}/>

                {/* Season Planning */}
                <Route exact path={'/resourceManagement/seasonPlanning'} component={SeasonProgramManager}/>

                {/* Rights */}
                <PrivateRoute exact path='/Rights/rightManagement' component={RightManager} />
                <PrivateRoute exact path='/Rights/userManagement' component={UserManager} />

                {/* Development */}
                <PrivateRoute exact path = '/development/tools' component={LogManager} />

                {/* Packman */}
                <PrivateRoute exact path = '/packman/intakeSummary' component={IntakeSummaryManager} />
            </Switch>
        </Suspense>
    );
};

export default Routes;